var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.printStyle() },
    [
      _vm.print
        ? _c(
            "vs-row",
            { staticClass: "mt-4 fixRowWidth" },
            [
              _c(
                "vs-col",
                [
                  _c(
                    "vx-card",
                    {
                      staticClass: "mb-4",
                      attrs: { title: _vm.$t("respostas") },
                    },
                    [
                      _c("div", { staticClass: "vx-row w-full" }, [
                        _c("div", { staticClass: "vx-col sm:w-4/12 w-4/12" }, [
                          _c("b", [_vm._v(_vm._s(_vm.$t("participant")))]),
                        ]),
                        _c("div", { staticClass: "vx-col sm:w-5/12 w-5/12" }, [
                          _c("b", [_vm._v(_vm._s(_vm.$t("Questionnaire")))]),
                        ]),
                        _c("div", { staticClass: "vx-col sm:w-3/12 w-3/12" }),
                      ]),
                      _c("div", { staticClass: "vx-row w-full" }, [
                        _c("div", { staticClass: "vx-col sm:w-4/12 w-4/12" }, [
                          _vm._v(_vm._s(_vm.userName)),
                        ]),
                        _c("div", { staticClass: "vx-col sm:w-5/12 w-5/12" }, [
                          _vm._v(_vm._s(_vm.details.questionnaireName)),
                        ]),
                        _c("div", { staticClass: "vx-col sm:w-3/12 w-3/12" }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-2 mb-2 grid grid-cols-3 align-baseline",
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "font-semibold block row-start-1" },
                            [_vm._v(_vm._s(_vm.$t("points")))]
                          ),
                          _c("span", { staticClass: "row-start-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.formatFloat(_vm.answerData.total_score)
                              ) +
                                " / " +
                                _vm._s(_vm.answerData.max_total_score) +
                                " " +
                                _vm._s(_vm.scorePercentage(_vm.answerData))
                            ),
                          ]),
                          _c(
                            "label",
                            { staticClass: "font-semibold block row-start-1" },
                            [_vm._v("Média da Turma")]
                          ),
                          _c("span", { staticClass: "row-start-2" }, [
                            _vm._v(
                              _vm._s(_vm.formatFloat(_vm.details.scoreAverage))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vs-row",
        { staticClass: "mt-2" },
        [
          _c("vs-col", { staticClass: "mt-2" }, [
            _c(
              "div",
              [
                _vm._l(_vm.answers, function (field, i) {
                  return _c(
                    "vs-row",
                    { key: i },
                    [
                      _c(
                        "vs-col",
                        {
                          staticClass: "list-item",
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "left",
                            "vs-align": "left",
                            "vs-w": "12",
                          },
                        },
                        [
                          _c(
                            "question-show-card",
                            _vm._b(
                              {
                                attrs: { field: field },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "after",
                                      fn: function () {
                                        return [
                                          !_vm.print
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("feather-icon", {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName:
                                                            "v-tooltip.top",
                                                          value: {
                                                            content:
                                                              _vm.$t(
                                                                "Open_comments"
                                                              ),
                                                            delay: {
                                                              show: 1000,
                                                              hide: 100,
                                                            },
                                                          },
                                                          expression:
                                                            "{\n                        content: $t('Open_comments'),\n                        delay: { show: 1000, hide: 100 }\n                      }",
                                                          modifiers: {
                                                            top: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "mb-2 float-right cursor-pointer",
                                                      attrs: {
                                                        badge:
                                                          _vm.unreadComments(
                                                            field.comments,
                                                            field.question_id
                                                          ),
                                                        icon: "MessageSquareIcon",
                                                        svgClasses:
                                                          "text-success",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.showCommentSectionModal(
                                                            field
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "question-show-card",
                              _vm.$props,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _vm.print
                  ? _c("vs-row", [
                      _c("div", { staticClass: "vx-col block w-full grid" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-stretch mx-auto place-self-center",
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "text-primary text-sm mr-2" },
                              [_vm._v(_vm._s(_vm.$t("Powered_by")))]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://educatena.com.br",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { "max-width": "100px" },
                                  attrs: {
                                    src: require("@/assets/images/logo/logo.png"),
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.$t("comments"), active: _vm.showCommentSection },
          on: {
            "update:active": function ($event) {
              _vm.showCommentSection = $event
            },
          },
        },
        [_c("AnswerCommentSection", { ref: "commentsection" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }